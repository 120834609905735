import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71c7fcec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pluginsPicList"
};
const _hoisted_2 = ["src"];
import { thumb } from "@/utils/index";
export default {
  __name: 'Piclist',
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          url: "",
          poster: ""
        };
      }
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item)
        }, null, 8, _hoisted_2)]);
      }), 128))]);
    };
  }
};